

#reactcalc {
    background: #3a4352;
}

#reactcalc h2 {
    font-size: 3em;
}

#reactcalc #question {
    padding: 15px;
    font-size: 1em;
    text-align: center;
    /* min-height: 90%; */
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#reactcalc #question h2 {
    /* font-size: 1.7em; */
    border:none;
    color: var(--text-color-light);
}

#reactcalc #question p {
    /* font-size: 1.5em; */
    /* color: var(--sec-color); */
}


#reactcalc #results-title {
    color: #282c34;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

#reactcalc #results-title h2{
    color:#000;
    /* font-size: 2.5em; */
}
#reactcalc #results-title p{
    font-size: 1.1em;
    line-height: 1.3em;
}

#reactcalc #results {
    color: #282c34;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

#reactcalc #results dl {
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: #282c34 2px dotted;
    margin-bottom:5px;
}

#reactcalc #results dl dt {
    text-align: left;
}

#reactcalc #results dl dt .answer {
    font-weight: normal;
    color: #a96b00;
}

#reactcalc #results dl dd {
    text-align: right;
    font-weight: bolder;
    font-size: 1.3em;
    display: flex;
    align-items: flex-end;
    color: #282c34;
}

#reactcalc .total {
    font-size: 3em;
    text-align: right;
    color: #282c34;
}

#reactcalc .total-answer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#reactcalc #calc-navbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0 15px 0;
}

#reactcalc #calc-navbar .nav-point {
    display: flex;
    flex-direction: row;
}

#reactcalc #calc-navbar .nav-item{
    /* width: 20%; */
    /* margin: 0 10px 0 0; */
    font-size: .8em;
}
#reactcalc #calc-navbar .nav-point .divider {
    /* width: 20%; */
    margin: 0 10px 0 10px;
    font-size: .8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#reactcalc #calc-navbar .nav-item.active {
    border-bottom: 1px dashed var(--warning);
    cursor: pointer;
}

#reactcalc #action-result {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-self: center;
    align-items: center;
    height:60px;
}

#reactcalc #action-result .res-left {
    font-size: 3em;
    margin-right: 30px;
    color: var(--light);
}
#reactcalc #action-result .res-right {

}
#reactcalc .clearbtn {
    margin: 20px 0;
    font-size: 1.3em;
}
#reactcalc .clearbtn-wt {
    margin: 0 20px;
    font-size: 1.3em;
}

.se-active {
    color: rgb(255, 185, 8)!important;
}

.track {
    color: #fff!important;
}

.rail {
    color: #fff!important;
}

.mark {
    color: #fff!important;
}

.done {
    color:#ffe100!important;
    text-decoration: underline dashed!important;
}

.acttive-step {
    color: #fff!important;
}


